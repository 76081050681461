import $ from 'jquery';
import intlTelInput from 'intl-tel-input';
import Logger from "js-logger";
import "../../../node_modules/intl-tel-input/build/js/utils.js"
import {AddCustomParsleyError, RemoveCustomParsleyError} from "../Validations/FormValidator";

Logger.useDefaults();
if (process.env.NODE_ENV !== "development") {
    Logger.setLevel(Logger.ERROR);
}
const parsleyErrorTag = 'tel-error';

let countryCode = "AU";

const UTIL_SCRIPT_URL = "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.18/js/utils.min.js";

export const SetCountryCodeForTelInputs = (code) => {
    countryCode = code;
}

/**
 *
 * @param {HTMLElement[]} fields
 */
export const InitializeLandlineFields = (fields) => {
    
    countryCode = $("#hfClubCountry").val() ?? "AU";

    fields.forEach((element) => {

        if (!element) return; 
        
        const instance = intlTelInput(element, {
            preferredCountries: ["AU", "NZ"],
            initialCountry: countryCode,
            nationalMode: true,
            allowDropdown: true,
            utilsScript: UTIL_SCRIPT_URL,
            placeholderNumberType: intlTelInputUtils.numberType.FIXED_LINE
        });
        $(element).on('change', () => {
            HandleValidation(instance, element, intlTelInputUtils.numberType.FIXED_LINE);
        })


    })

}

/**
 *
 * @param {HTMLElement[]} fields
 */
export const InitializeMobileFields = (fields) => {

    countryCode = $("#hfClubCountry").val() ?? "AU";
    
    fields.forEach((element) => {

        if (!element) return;
        
        const instance = intlTelInput(element, {
            preferredCountries: ["AU", "NZ"],
            initialCountry: countryCode,
            separateDialCode: true,
            nationalMode: false,
            utilsScript: UTIL_SCRIPT_URL,
            placeholderNumberType: intlTelInputUtils.numberType.MOBILE
        });
        $(element).on('change', () => {
            HandleValidation(instance, element, intlTelInputUtils.numberType.MOBILE);
        })

    })
}


/**
 *
 * @param {HTMLElement[]} fields
 */
export const InitializeMixedNumberFields = (fields) => {

    countryCode = $("#hfClubCountry").val() ?? "AU";
    
    fields.forEach((element) => {
        
        if (!element) return;
        
        const instance = intlTelInput(element, {
            preferredCountries: ["AU", "NZ"],
            initialCountry: countryCode,
            nationalMode: true,
            allowDropdown: true,
            autoHideDialCode:false,
            utilsScript: UTIL_SCRIPT_URL,
            placeholderNumberType: intlTelInputUtils.numberType.FIXED_LINE_OR_MOBILE
        });

        $(element).on('change', () => {
            HandleValidation(instance, element, intlTelInputUtils.numberType.FIXED_LINE_OR_MOBILE);
        })

    })
}

/**
 * Handles the validation on the three phone types (mobile, landline and Mixed)
 * @param {Iti} instance
 * @param {HTMLElement} field
 * @param {int} verificationType
 */
const HandleValidation = (instance, field, verificationType) => {

    let errorMessage;

    const field$ = $(field);
    const isRequired = field$.is(":required");
    
    const type = instance.getNumberType();
    
    // Remove the current errors if they exist
    RemoveCustomParsleyError(field$, parsleyErrorTag);

    // Validation Parameters
    const isEmpty = isRequired && !field$.val(); // Empty mobile field where number is required
    const invalidLandline = verificationType === intlTelInputUtils.numberType.FIXED_LINE && type === intlTelInputUtils.numberType.MOBILE && field$.val() !== "";
    const invalidMixed = field$.val() !== "" && verificationType === intlTelInputUtils.numberType.FIXED_LINE_OR_MOBILE && (type !== intlTelInputUtils.numberType.MOBILE && type !== intlTelInputUtils.numberType.FIXED_LINE);
    const invalidMobile = field$.val() !== "" && verificationType === intlTelInputUtils.numberType.MOBILE && (type !== intlTelInputUtils.numberType.MOBILE);
    
    const mobileFormat = intlTelInputUtils.getExampleNumber(countryCode, true, 1).replace(new RegExp("[0-9]", "g"), "x");
    const landlineFormat = intlTelInputUtils.getExampleNumber(countryCode, true, 0).replace(new RegExp("[0-9]", "g"), "x");

    if (isEmpty) {
        errorMessage = "Please provide a phone number";
    } else if (invalidLandline) {
        errorMessage = "Cannot be a mobile number - Leave blank if no landline";
    } else if (invalidMixed) {
        // Mobile format is incorrect but can be a mobile or landline number
        errorMessage = "Invalid number provided. The valid format is: ";
        errorMessage += landlineFormat === mobileFormat ? mobileFormat : mobileFormat + " or " + landlineFormat;
    } else if (invalidMobile) {
        errorMessage = `Invalid number provided. The valid format is ${mobileFormat}`;
    }

    if (errorMessage) {
        AddCustomParsleyError(field$, errorMessage, parsleyErrorTag)
    } else {
        instance.setNumber(field$.val());
    }
}

/**
 * Gets the formatted version of the mobile number to suit the schools that will be syncing it back to their admin systems
 * @param {HTMLElement} field Field to fetch number from
 * @param {string} clubCountry Club country ID
 * @returns {string|null} Mobile number
 */
export const GetFormattedMobileNumber = (field, clubCountry) => {
    
    let mobileNumber = null;
    
    try {
        const instance = intlTelInputGlobals.getInstance(field);

        if (instance) {
            mobileNumber = instance.getNumber();
            const selectedCountry = instance.getSelectedCountryData().iso2;
            if (selectedCountry === "au" && countryCode.toLowerCase() === "au") {
                mobileNumber = mobileNumber.replace(" ", "");
                return (mobileNumber.substr(0, 6) + " " + mobileNumber.substr(6, 3) + " " + mobileNumber.substr(9, 3)).replace("+61", "0");
            } else if (selectedCountry === "nz" && countryCode.toLowerCase() === "nz") {
                // We don't need to convert to an international format so convert back to standard format
                mobileNumber = mobileNumber.replace("+64", "0");
                mobileNumber = mobileNumber.replace("-", " ");
                return mobileNumber;
            }
        }
    } catch (e) {
        Logger.warn("Tried to fetch a mobile phone number that failed", e)
    }

    return mobileNumber;

}

/**
 * Checks whether we are fetching a mobile or landline for mixed fields
 * @param {HTMLElement} field Field to fetch number from
 * @param {string} clubCountry Country code for the club
 * @returns {string|null} Either landline or mobile number
 */
export const GetFormattedMixedNumber = (field, clubCountry) => {

    let mixedNumber = null;
    
    try {
        const instance = intlTelInputGlobals.getInstance(field);


        if (instance) {

            const type = instance.getNumberType();

            if (type  === intlTelInputUtils.numberType.MOBILE) {
                return GetFormattedMobileNumber(field, clubCountry);
            } else {
                return GetFormattedLandlineNumber(field, clubCountry);
            }

        }

    } catch (e) {
        Logger.warn("Tried to fetch a mixed phone number that failed", e)
    }

    return mixedNumber;

}

/**
 * Gets the formatted version of the landline number to suit the schools that will be syncing it back to their admin systems
 * @param {jQuery} field Field to fetch number from
 * @returns {string|null} Formatted landline number
 */
export const GetFormattedLandlineNumber = (field, clubCountry) => {
    
    
    let landlineNumber = $(field).val();
    const originalValue = $(field).data("original");
    
    // Remove the spaces from original value and compare it with the current value. If they are the same, we return the
    // original value. Intl-tel-input, on load, will trim the white space.
    // We ignore it with mobile numbers as they are returning the instance number, not the value
    if (originalValue && originalValue.replace(/\s/g, "") === landlineNumber) {
        return originalValue;
    }
    
    if (landlineNumber !== undefined) {
        landlineNumber = landlineNumber.replace("(", "");
        landlineNumber = landlineNumber.replace(")", "");
        landlineNumber = landlineNumber.replace("-", " ");
    }
    
    return landlineNumber;
    
}
