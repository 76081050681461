import 'parsleyjs';
import "../../../Styles/Consent2Go/Utilities/parsley.scss";
import Logger from "js-logger";
import $ from "jquery";
import moment from "moment";

Logger.useDefaults();
if (process.env.NODE_ENV !== "development") {
    Logger.setLevel(Logger.ERROR);
}


export const InitializeValidation = (containerId) => {
    const fieldsToValidate = GetAllFieldsToValidate(containerId);

    for (const element of fieldsToValidate) {
        element.parsley();
    }
};

/**
 * Validate the form within the given container
 * @param {string} containerId
 * @returns {Promise<boolean>}
 * @constructor
 */
export const ValidateForm = async (containerId) => {

    let isValid = true;
    const fieldsToValidate = GetAllFieldsToValidate(containerId);
    for (const element of fieldsToValidate) {
        try {
            if (element.hasClass("custom-error")) {
                return false;
            }
            
            const valid = await element.parsley().whenValidate();
            if (valid === false) {
                Logger.debug("Unable to validate: ", element);
                isValid = false;
            }
        } catch (e) {
            Logger.debug("Element is invalid", element, e);
            isValid = false;
        }
    }
    
    if (ValidateSupportingDocuments(containerId) === false) {
        isValid = false;
    }
    
    if (ValidateYesNoToggles(containerId) === false) {
        Logger.debug("Yes no buttons invalid", containerId);
        isValid = false;
    }
    
    if (ValidateTimeFields(containerId) === false) {
        Logger.debug("Start time is not after end time", containerId);
        isValid = false;
    }

    return isValid;
};

const ValidateTimeFields = (containerId) => {
    
    const timeFields = $(`#${containerId}`).find("[data-time-before]");
    let timesValid = true;
    
    timeFields.each((index, element) => {
        
        // Get the end time field
        element = $(element);
        const endTimeField = $(`#${element.data("time-before")}`);
        if (endTimeField) {
            // Get both the start time and end time
            const startTime = moment(element.val(), "HH:mm");
            const endTime = moment(endTimeField.val(), "HH:mm");
            const errorContainer = $(`#${element.data("error-container")}`);

            
            // Remove the errors
            element.removeClass("error");
            endTimeField.removeClass("error");
            if (errorContainer.length > 0) errorContainer.hide();
            
            // Check if the start time is before the end time
            if (startTime.isAfter(endTime)) {
                element.addClass("error");
                endTimeField.addClass("error");
                if (errorContainer.length > 0) errorContainer.show();
                timesValid = false;
            }
        }
        
    })
    
    return timesValid;
    
}

/**
 * Looks through the given container and checks for fields that require supporting documents
 * Generally, the file input will be hidden and it will have a fake input to show the file name and extension.
 * We use this to display an error along with a custom error field as Parsley doesn't play well with button groups
 * (often used for supporting document inputs)
 * @param {string} containerId Id of the container to search for supporting document required fields
 * @returns {boolean} All fields are valid
 */
const ValidateSupportingDocuments = (containerId) => {
    
    const supportingDocuments = $(`#${containerId}`).find("[data-requires-supporting-document]");
    let supportingDocumentsValid = true;
    supportingDocuments.each((index, element) => {
        
        const inputField = $(element);
        if (!inputField.is(":disabled")) {
            const errorElement = inputField.data("supporting-document-error");
            const pseudoFileInput = inputField.data("supporting-document-input");
            if (!$(`#${pseudoFileInput}`).val()) {
                supportingDocumentsValid = false;
                $(`#${errorElement}`).show();
                $(`#${pseudoFileInput}`).addClass("parsley-error");
            } else {
                $(`#${errorElement}`).hide();
                $(`#${pseudoFileInput}`).removeClass("parsley-error");
            }
        }
    });

    return supportingDocumentsValid;
};

const ValidateYesNoToggles = (containerId, positiveBtnClass='c2g-yes', negativeBtnClass='c2g-no') => {
    const yesNoToggles = $(`#${containerId}`).find("[data-yes-no-required]");
    let yesNoValid = true;
    
    yesNoToggles.each((index, element) => {

        const yesNoContainer = $(element);
        if (yesNoContainer.is(":visible")) {

            const errorContainer = $(`#${yesNoContainer.data("yes-no-error-container")}`);
            
            // Check for yes no answers
            const yesButton = yesNoContainer.find(`.${positiveBtnClass}`).first();
            const noButton = yesNoContainer.find(`.${negativeBtnClass}`).first();
            if (!yesButton.hasClass("active") && !noButton.hasClass("active")) {
                yesNoContainer.addClass("parsley-error");
                if (errorContainer.length > 0) errorContainer.show();
                
                yesNoContainer.on("click", () => {
                    yesNoContainer.removeClass("parsley-error");
                    if (errorContainer.length > 0) errorContainer.hide();
                });
                
                yesNoValid = false;
            } else {
                yesNoContainer.removeClass("parsley-error");
                if (errorContainer.length > 0) errorContainer.hide();
                yesNoContainer.off("click");
            }
        }
        

    });

    return yesNoValid;
};

/**
 * Adds a custom Parsley error
 * @param {jQuery|HTMLElement} field Field to add error to
 * @param {string} errorMessage Error message to display
 * @param {string} errorId ID of the error message. If there is more than one error being added, use custom error ID
 */
export const AddCustomParsleyError = (field, errorMessage, errorId='customError') => {
    // Initialize Parsley on the specific field
    $(field).parsley().addError(errorId, {message: errorMessage});
    $(field).addClass("custom-error");
};

/**
 * Removes the custom Parsley error
 * @param {jQuery|HTMLElement} field Field to remove error from
 * @param {string} errorId ID of the error message to remove
 */
export const RemoveCustomParsleyError = (field, errorId='customError') => {
    $(field).parsley().removeError(errorId);
    $(field).removeClass("custom-error");
};

const GetAllFieldsToValidate = (containerId) => {
    const container = $(`#${containerId}`);
    
    // Fetch all imports
    const inputsToValidate = container.find("input, select, textarea");
    let fieldsToValidate = inputsToValidate.toArray();

    // Remove fields that are disabled
    fieldsToValidate = fieldsToValidate.filter(element => {
        return !$(element).is(":disabled") && $(element).attr("no-validation") !== "" && $(element).attr("type") !== "hidden";
    });

    // Convert to JQuery array
    fieldsToValidate = fieldsToValidate.map(element => $(element));   
    return fieldsToValidate;
};
