/**
 * Intended for exposing the intl-tel-input extensions to the global scope
 * Useful where we need to access the intl-tel-input when mixing with the old JavaScript code for C2G
 */
import {
    GetFormattedLandlineNumber, GetFormattedMixedNumber,
    GetFormattedMobileNumber,
    InitializeLandlineFields,
    InitializeMixedNumberFields,
    InitializeMobileFields
} from "./intlTelInputExtensions";


require('!style-loader!css-loader!intl-tel-input/build/css/intlTelInput.min.css');

window.InitializeMobileNumbers = InitializeMobileFields;
window.InitializeLandlineNumbers = InitializeLandlineFields;
window.InitializeMixedNumbers = InitializeMixedNumberFields;
window.GetMobileNumber = GetFormattedMobileNumber;
window.GetLandlineNumber = GetFormattedLandlineNumber;
window.GetMixedNumber = GetFormattedMixedNumber;